.instrument-search-bar-container{
    width: 100%;
    height: 100px;
    padding: 15px;
    margin: 15px;
    background: white;
    border-radius: 4px;
    display: flex;
}

.instrument-search-input-field-container{
    padding: 5px;
}

.instrument-search-input-field{
    width: 100%;
    height: 100%;
}

.instrument-search-bar-name{
    width: auto;
    padding: 10px;
}

.instrument-search-button-div{
    padding-top: 10px
}

.instrument-search-label{
    height: 50px;
    width: 100%;
    background: #0f6674;
}

.instrument-search-button{
    width: 100%;
}