.title{
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
}

.title2{
    font-size: 14px;
    font-style: italic;
    margin: 0px;
    padding-left: 10px;
}

.title-div{
    padding-left: 10px;
}
