.sidebar {
    background: white;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 4px;
}

.sidebarWrap {
    width: 100%;
}