.time{
    font-size: 10px;
    font-style: italic;
}

.robot{
    font-size: 14px;
    font-weight: bold;
}

.message{
    font-size: 12px;
}

.sub-message{
    font-size: 10px;
}
