.table-header22{
    border: 1px;
    border-style: solid;
    background: #e2e2e2;
    border-color: #6c757d;
    font-size: 14px;
}
.table-row22{
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #6c757d;
    font-size: 14px;
}

.instrument-results-container{
    width: 100%;
    height: 100%;
    padding: 15px;
}