.portfolio-settings-name-field{
    width: 250px;
}

.portfolio-settings-input-field{

}

.portfolio-settings-page-container{
    width: 100%;
    height: 855px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}